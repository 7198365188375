import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://ae1da3ef06dd4ad0a3fbd9d8cc585940@o1108100.ingest.sentry.io/6135540',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
